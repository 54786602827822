<template>
    <div class="js-entry o-entry c-html-entry">
        <iframe class="c-html-entry__iframe" v-if="entryData.Content.Index" :src="$xp.settings.baseUrl + 'xpedeo/' + entryData.Content.Index"></iframe>
        <iframe class="c-html-entry__iframe" v-else-if="entryData.Content.Url" :src="(entryData.Content.Url.indexOf('http://') === 0 || entryData.Content.Url.indexOf('https://') === 0) ? entryData.Content.Url : $xp.settings.baseUrl +'pda/'+ entryData.Content.Url"></iframe>
        <div :class="{'c-html-player__controls': true, 'is-hidden': $xp.navigation.isNavHidden, 'landscape': this.$xp.screen.orientation==='landscape-primary' }" v-if="entryNeedsVolumeControl">
          <div :class="{'c-html-player__volume-wrapper': true}">
            <button class="c-html-player__volume-button" @click="showVolumeControl = !showVolumeControl" tabindex="0" :aria-label="$t('global.setVolume')" v-if="true">
                <span class="xp xp-volume-up xp--large" aria-hidden="true" v-if="$xp.media.volume >= 0.5"></span>
                <span class="xp xp-volume-down xp--large" aria-hidden="true" v-if="$xp.media.volume < 0.5 && $xp.media.volume !== 0"></span>
                <span class="xp xp-volume-off xp--large" aria-hidden="true" v-if="$xp.media.volume === 0" name="volume-off"></span>
            </button>
            <div class="c-html-player__volume-slider"  v-if="showVolumeControl" >
                <slider v-bind:value.sync="playbackVolume" :orientation="$xp.screen && $xp.screen.orientation==='landscape-primary'?'horizontal':'vertical'" :trackSize=10></slider>
            </div>
          </div>
        </div>
    </div>

</template>

<script>
import { handleExternalUrl } from '@/util/router-guards'
import entryMixin from '../../mixins/entry'

export default {
  mixins: [entryMixin],
  data () {
    return {
      showVolumeControl: false,
      entryNeedsVolumeControl: false
    }
  },
  computed: {
    playbackVolume: {
      get: function () {
        return parseFloat(this.$xp.media.volume)
      },
      set: function (v) {
        if (!Number.isNaN(v)) {
          this.$xp.media.setGlobalVolume(v)

          const volumeMessage = { method: 'volumeChanged', value: v }
          const iframes = document.getElementsByClassName('c-html-entry__iframe')
          const iframeElement = iframes.length > 0 ? iframes[0] : null
          if (iframeElement) {
            iframeElement.contentWindow.postMessage(volumeMessage, '*')
          }
        } else {
          console.log('invalid volume: ' + v)
        }
      }
    }
  },
  mounted () {
    console.log('htmlentry Mounted', this.entryData)
    window.addEventListener('message', this.onMessage, false)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.onMessage, false)
  },
  methods: {
    onMessage (e) {
      let method = ''
      if (typeof e.data === 'string') {
        method = e.data
      } else if (typeof e.data === 'object') {
        method = e.data.method
      } else {
        throw Error('event data must be a string or an object contains method property as string')
      }
      console.log('HtmlEntry.onMessage:', e.data, ', method', method)
      switch (method) {
        case 'touchstart':
        case 'pointerdown':
          this.onTouchStart()
          break
        case 'touchend':
        case 'pointerup':
          this.onTouchEnd()
          break
        case 'back':
          this.$xp.history.back()
          break
        case 'showPage':
          this.$router.push('/page/' + e.data.pageID)
          break
        case 'goToNextPage':
          this.$router.push('/page/' + this.$xp.content.pageData.NextPageID)
          break
        case 'openLink':
          handleExternalUrl(e.data.href)
          break
        case 'showVolumeControl':
          this.sendMediaVolumeMessage()
          this.entryNeedsVolumeControl = true
          break
        case 'hideVolumeControl':
          this.entryNeedsVolumeControl = false
          break
      }
    },
    onTouchStart () {
      const e = new Event('pointerdown', { bubbles: true })
      this.$el.dispatchEvent(e)
    },
    onTouchEnd () {
      const e = new Event('pointerup', { bubbles: true })
      this.$el.dispatchEvent(e)
    },
    sendMediaVolumeMessage () {
      const volumeMessage = { method: 'volumeChanged', value: this.$xp.media.volume }
      const iframes = document.getElementsByClassName('c-html-entry__iframe')
      const iframeElement = iframes.length > 0 ? iframes[0] : null
      if (iframeElement) {
        iframeElement.contentWindow.postMessage(volumeMessage, '*')
      }
    }
  },
  watch: {
    '$xp.navigation.isNavHidden': function (value) {
      if (value === true) {
        this.showVolumeControl = false
      }
    }
  }
}
</script>
